.last-updated-wrapper {
  position: absolute;
  left: 24px;
  margin-top: 8px;
  font-size: 16px;

  .last-updated {
    font-weight: bold;
  }
}
