.stok-gudang-mobile {
  .title {
    font-size: 20px;
  }

  .ant-divider-horizontal {
    margin: 16px 0;
  }

  .detail-item-key {
    margin: 0px !important;
    width: 150px;
  }

  .detail-item-value {
    margin: 0px !important;
    font-weight: bold;
  }
}

.ant-carousel {
  .slick-slide > div > div {
    justify-content: center;
    align-items: center;
    width: 100% !important;
    display: flex !important;
    margin: auto;
    padding-top: 12px;
    padding-bottom: 32px;
  }

  .slick-prev {
    color: #364d79;
    opacity: 1;
    margin-left: 12px;
  }
  .slick-next {
    color: #364d79;
    opacity: 1;
    margin-right: 12px;
  }

  .slick-dots {
    li {
      button {
        background: #364d79;
      }

      &.slick-active {
        button {
          background: #364d79;
          opacity: 1;
        }
      }
    }
  }
}