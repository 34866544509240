.round-icon {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin: auto;
}
.green-indicator {
  background-color: green;
}
.yellow-indicator {
  background-color: yellow;
}
.orange-indicator {
  background-color: orange;
}
.black-indicator {
  background-color: black;
}

.legend {
  border: 2px solid #FAFAFA;
  padding: 15px;
  width: 100%;
  margin: 20px auto;
  text-align: center;
  font-family: Arial, sans-serif;
  box-shadow: 0 5px 10px #FAFAFA;

  .legend-title {
    font-size: 20px;
    font-weight: bold;
  }
  
  .legend-line {
    border-top: 1px solid #FAFAFA;
    margin: 10px 0;
  }
  
  .legend-item {
    display: inline-flex;
    margin: 10px 20px;
    font-size: 16px;

    .circle {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      display: inline-block;
      margin-right: 10px;
    }
  }
}

