.card-image-checkbox {
  position: absolute;
  top: 0px;
  right: 4px;
  
  .ant-checkbox-inner {
    border: 1px solid black;
  }
}

.make-first-top {
  .ant-space-item {
    align-self: flex-start;
  }
}