.ant-segmented {
  width: 100%;
  margin-bottom: 24px;
  .ant-segmented-group {
    .ant-segmented-item {
      width: 50%;

      &.ant-segmented-item-selected {
        font-weight: bold;
      }
    }
  }
}