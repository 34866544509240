/* Container styles */
.login-container {
    justify-content: space-between;
    align-items: center;
    height: 100vh;
    margin: 0 auto;
}

/* Left column styles */
.left-column {
    flex-basis: 50%;
    padding: 20px;
    max-width: 400px;
    margin: 0 auto 100px;
}

/* Right column styles */
.right-column {
    flex-basis: 50%;
    height: 100%;
}

/* Image slider styles */
.image-slider {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
}

.image-slider img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    transition: transform 0.5s ease-in-out;
}

.logo-login {
    height: 160px !important;
    padding: 30px 0 28px !important;
}

.greetings {
    text-align: center;
    margin-bottom: 16px;
    display: block;
    top: 0px;

    h1 {
        margin-block-end: 8px;
        font-size: 48px;
    }
}

.copyright {
    padding-bottom: 16px;
    display: block;
    position: absolute;
    bottom: 16px;
    float: inline-end;
    width: 400px;
    text-align: right;

    .pt-name {
        font-weight: bold;
    }
}

.ant-form-item-control {
    max-width: 100%;
}


@media screen and (max-width: 600px) {
    .login-container {
        flex-direction: column-reverse;
    }

    .greetings {
        text-align: center;
        margin-bottom: 24px;
        display: block;
        position: relative;
        top: 0px;
    
        h1 {
            margin-block-end: 8px;
            font-size: 48px;
        }
    }

    .copyright {
        padding-top: 16px;
        display: block;
        width: 100%;
        position: relative;
        text-align: center;
    
        .pt-name {
            font-weight: bold;
        }
    }

    .left-column {
        width: 65%;
    }

    .image-slider img {
        img {
            width: 100%;
            height: 100%;
            object-fit: none;
            object-position: top;
            transition: transform 0.5s ease-in-out;
        }
    }
}