.ant-layout {
  min-height: 100vh;
}

.pointer {
  cursor: pointer;
}

.logout-btn {
  width: auto;
  float: right;
  margin-right: 18px;
}

.logo {
  height: 80px;
  padding: 12px;
  margin: 0 auto;
}

.spinner {
  width: 50px !important;
  height: 50px !important;
}

.sider {
  width: 250px !important;
  flex: 0 0 250px !important;
  max-width: 250px !important;
  min-width: 250px !important;
  background: #fff !important;

  &.ant-layout-sider-collapsed {
      width: 100px !important;
      flex: 0 0 100px !important;
      max-width: 100px !important;
      min-width: 100px !important;
  }

  .sider-menu {
    background: #fff;
  
    .ant-menu-item, .ant-menu-item:hover {
      color: #000 !important;
    }

    .ant-menu-item-selected {
      background: rgb(245, 245, 245);
    }

    .ant-menu-submenu {
      .ant-menu-submenu-title {
        color: #000;
      }

      .ant-menu-sub {
        &.ant-menu-inline {
          background: rgb(229, 226, 226);
  
          .ant-menu-item {
            color: #000;
          }
        }
  
        .ant-menu-item-selected {
          background: rgb(245, 245, 245);
        }
      }
    }
  }
}

.ant-modal {
  .ant-modal-header {
    padding-bottom: 16px;
    margin-bottom: 24px;
    border-bottom: 1px solid;
  }
}