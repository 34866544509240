.stok-bahan-mobile {
  .title {
    font-size: 20px;
  }

  .ant-divider-horizontal {
    margin: 16px 0;
  }

  .detail-item-key {
    margin: 0px !important;
    width: 150px;
  }

  .detail-item-value {
    margin: 0px !important;
    font-weight: bold;
  }
}